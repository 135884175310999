import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import loadable from '@loadable/component';
import Loader from './Loader';

const AsyncLatters = loadable(
  () =>
    import(/* webpackChunkName: 'Letters' */ '../containers/Letters/Letters'),
  {
    fallback: <Loader />
  }
);

const AsyncReport = loadable(
  () => import(/* webpackChunkName: 'Report' */ '../containers/Report/Report'),
  {
    fallback: <Loader />
  }
);

class Routes extends Component {
  render() {
    const PATH = this.props.path;
    return (
      <Switch>
        <Route exact path={`${PATH}report`} component={AsyncReport} />
        <Route path={`${PATH}letters`} component={AsyncLatters} />
      </Switch>
    );
  }
}

export default Routes;
