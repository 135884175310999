import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import './Navigation.scss';

class Navigation extends Component {
  render() {
    const PATH = this.props.path;
    return (
      <nav className="navigation">
        <NavLink
          to={`${PATH}report`}
          activeClassName="selected"
          onClick={this.setNavPosition}
        >
          report
        </NavLink>
        <NavLink
          to={`${PATH}letters`}
          activeClassName="selected"
          onClick={this.setNavPosition}
        >
          letters
        </NavLink>
      </nav>
    );
  }
}

export default Navigation;
