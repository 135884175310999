import React, { Component } from 'react';

import Routes from './utils/Routes';
import Navigation from './components/Navigation/Navigation';

class App extends Component {
  constructor(props) {
    super(props);
    this.setNavPosition = this.setNavPosition.bind(this);
  }

  setNavPosition() {
    // const element = document.querySelector('.selected');
    // element.scrollIntoView();
    // document.querySelector('.navigation').scrollLeft = document.querySelector('.selected').offsetLeft;
  }

  componentDidMount() {
    this.setNavPosition();
  }
  render() {
    const { path } = this.props.match;
    return (
      <div className="container">
        <Navigation path={path} />
        <div className="content">
          <Routes path={path} />
        </div>
      </div>
    );
  }
}

export default App;
